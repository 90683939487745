<template>
  <span>
    <a-button type="primary" @click="visible = true">新增考试</a-button>

    <a-modal title="选择考试" :visible="visible" @cancel="cancel" :footer="null" width="1000px">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="queryForm"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input v-model="queryForm.name" placeholder="考试名称" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-cascader
                :changeOnSelect="true"
                placeholder="归属目录"
                v-model="queryForm.type"
                :options="useableTree"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              v-if="$getPermission('/knowledge/exam/index/add')"
              @click="create"
              type="primary"
            >创建考试</a-button>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="考卷名" data-index="name"></a-table-column>

        <a-table-column title="考试时长(分钟)" data-index="duration" align="center"></a-table-column>

        <a-table-column title="归属目录" align="center">
          <template slot-scope="text">{{ getType(text) }}</template>
        </a-table-column>

        <a-table-column title="开始日期" data-index="startTime" align="center"></a-table-column>
        <a-table-column title="结束日期" data-index="endTime" align="center"></a-table-column>

        <a-table-column title="创建人" data-index="createBy" align="center"></a-table-column>
        <a-table-column title="创建日期" data-index="createAt" align="center"></a-table-column>

        <a-table-column align="center" title="操作" width="80px">
          <template slot-scope="text">
            <a href="#" @click.prevent="add(text)">选择</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>

    <a-modal title="请输入排序值" :visible="extraVisible" @cancel="extraVisible = false" :footer="null">
      <a-input-number :min="0" :max="9999" v-model="sort" />

      <div class="right" style="margin-top: 16px">
        <a-space>
          <a-button @click="extraVisible  = false">关闭</a-button>
          <a-button type="primary" @click="save">添加</a-button>
        </a-space>
      </div>
    </a-modal>
  </span>
</template>

<script>
import { fetchList } from "@/api/exam";

import study from "@/mixins/study";

export default {
  mixins: [study],

  data() {
    return {
      visible: false,

      queryForm: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selected: {},
      extraVisible: false,
      sort: 0
    };
  },

  watch: {
    visible() {
      this.getList();
    }
  },

  methods: {
    getList() {
      const { current, pageSize, queryForm } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...queryForm,
        type:
          Array.isArray(queryForm.type) && queryForm.type.length > 0
            ? queryForm.type[queryForm.type.length - 1]
            : undefined
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.queryForm = {};
      this.current = 1;
      this.getList();
    },

    getType(text) {
      let types = [];
      this.findFatherNames(types, text.type);
      return types.join("/");
    },

    create() {
      this.cancel();
      this.$router.push("/knowledge/exam/index/add");
    },
    add(text) {
      this.selected = text;
      this.extraVisible = true;
    },
    save() {
      if (typeof this.sort !== "number") {
        this.$message.error("请输入排序值");
        return;
      }
      this.$emit("select", {
        ...this.selected,
        sort: this.sort
      });

      this.extraVisible = false;
      this.sort = 0;
      this.selected = {};
      this.cancel();
    },

    cancel() {
      this.visible = false;
    }
  }
};
</script>