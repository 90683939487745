<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="课程目录" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-cascader
                :changeOnSelect="true"
                placeholder
                v-decorator="[
                  'pid',
                              { rules: [{ required: true, message: '请选择！' }] },
                    
                ]"
                :options="useableTree"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="课程名称" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-input
                v-decorator="[
                              'name',
                              { rules: [{ required: true, message: '请输入！' }] },
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="课程介绍" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-textarea
                placeholder
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                              'remark',
                              { rules: [{ required: true, message: '请输入！' }] },
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="课程标签">
              <a-input
                placeholder="多个标签按照逗号分隔"
                v-decorator="[
                              'label',
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排序值">
              <a-input-number
                style="width: 100%"
                placeholder="排序值越大越靠前，排序值相同，按照更新时间排序"
                v-decorator="[
                              'top',
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="课程封面">
              <Cover :cover="cover" @change="payload => cover = payload" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="章节" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <Chapters :list="chapters" @change="payload => chapters = payload" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import Cover from "./components/cover.vue";
import Chapters from "./components/chapters.vue";
import study from "@/mixins/study";

import request from "@/api/request";

function add(data) {
  return request({
    url: "/office-service/study/course/add",
    method: "post",
    data
  });
}

export default {
  name: "courseAdd",

  mixins: [study],

  components: {
    Cover,
    Chapters
  },

  data() {
    return {
      form: this.$form.createForm(this),
      cover: {},
      chapters: [],

      loading: false
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          if (!this.cover.id) {
            this.$message.error("请上传封面");
            return;
          }
          if (this.chapters.length === 0) {
            this.$message.error("请上传章节");
            return;
          }

          let uploading = false;
          this.chapters
            .filter(item => item.type === "course")
            .forEach(item => {
              if (item.progress < 100) {
                uploading = true;
              }
            });

          if (uploading) {
            this.$message.error("请等待文件上传成功");
          } else {
            console.log("this.chaps", this.chapters);

            const that = this;
            this.$confirm({
              title: "新增后无法修改课程，是否继续？",
              okText: "是",
              okType: "danger",
              cancelText: "否",
              onOk() {
                that.loading = true;
                add({
                  ...values,
                  pid: values.pid[values.pid.length - 1],
                  cover: that.cover.id,
                  chapters: that.chapters.map(item => {
                    return {
                      ...item,
                      attachment: item.uploadKey
                    };
                  })
                })
                  .then(() => {
                    that.$close(that.$route.path);
                  })
                  .finally(() => {
                    that.loading = false;
                  });
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}

.center {
  padding: 40px;
}
</style>
