import request from '@/api/request'

export function fetchToken() {
    return request({
        url: "/common-service/file/uptoken",
    })
}

export function fetchFileKey(data) {
    return request({
        url: '/common-service/file/fileskey',
        method: 'post',
        data,
    })
}

export function updateFile(data) {
    return request({
        url: '/common-service/file/sysfile/update',
        method: 'post',
        data,
    })
}