var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("新增考试")]),_c('a-modal',{attrs:{"title":"选择考试","visible":_vm.visible,"footer":null,"width":"1000px"},on:{"cancel":_vm.cancel}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.queryForm,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"考试名称"},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", $$v)},expression:"queryForm.name"}})],1),_c('a-form-model-item',[_c('a-cascader',{attrs:{"changeOnSelect":true,"placeholder":"归属目录","options":_vm.useableTree,"fieldNames":{
                label: 'name',
                value: 'id',
                children: 'children',
              }},model:{value:(_vm.queryForm.type),callback:function ($$v) {_vm.$set(_vm.queryForm, "type", $$v)},expression:"queryForm.type"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[(_vm.$getPermission('/knowledge/exam/index/add'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.create}},[_vm._v("创建考试")]):_vm._e()],1)])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"考卷名","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"考试时长(分钟)","data-index":"duration","align":"center"}}),_c('a-table-column',{attrs:{"title":"归属目录","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(_vm._s(_vm.getType(text)))]}}])}),_c('a-table-column',{attrs:{"title":"开始日期","data-index":"startTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"结束日期","data-index":"endTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"创建人","data-index":"createBy","align":"center"}}),_c('a-table-column',{attrs:{"title":"创建日期","data-index":"createAt","align":"center"}}),_c('a-table-column',{attrs:{"align":"center","title":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.add(text)}}},[_vm._v("选择")])]}}])})],1)],1),_c('a-modal',{attrs:{"title":"请输入排序值","visible":_vm.extraVisible,"footer":null},on:{"cancel":function($event){_vm.extraVisible = false}}},[_c('a-input-number',{attrs:{"min":0,"max":9999},model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}}),_c('div',{staticClass:"right",staticStyle:{"margin-top":"16px"}},[_c('a-space',[_c('a-button',{on:{"click":function($event){_vm.extraVisible  = false}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("添加")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }