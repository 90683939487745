<template>
  <div>
    <input
      ref="upload"
      style="display: none"
      type="file"
      :multiple="false"
      accept="image/*"
      @change="onChange"
    />

    <a-tooltip>
      <template slot="title">点击上传</template>
      <div class="upload" @click="onClick">
        <img v-if="cover.id" :src="cover.completePath" />
        <a-icon v-else type="plus" class="icon" />
      </div>
    </a-tooltip>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    cover: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      const hide = this.$message.loading("上传中...");

      uploadFile(data)
        .then(res => {
          console.log("upload res", res);
          if (Array.isArray(res) && res.length > 0) {
            this.$emit("change", res[0]);
          }
        })
        .finally(() => {
          hide();
          e.target.value = null;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.upload {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;
  .icon {
    font-size: 32px;
  }
  img {
    border-radius: 2px;
    width: 100%;
    display: block;
    cursor: pointer;
  }
}
</style>