<template>
  <div>
    <a-space>
      <a-button @click="visible = true">新增课程</a-button>
      <Exam @select="onSelectExam" />
    </a-space>
    <Padding />

    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="序号" width="70px" align="center">
        <template slot-scope="text, record, index">{{ index + 1 }}</template>
      </a-table-column>

      <a-table-column title="章节名称" data-index="name"></a-table-column>
      <a-table-column title="章节类型" align="center" width="80px">
        <template slot-scope="text">
          <a-tag color="green" v-if="text.type === 'course'">课程</a-tag>
          <a-tag color="blue" v-if="text.type === 'exam'">考试</a-tag>
        </template>
      </a-table-column>
      <a-table-column title="时长(分钟)" data-index="duration" align="center" width="120px"></a-table-column>
      <a-table-column title="排序值" data-index="sort" align="center" width="80px"></a-table-column>

      <a-table-column title="附件名">
        <template slot-scope="text">
          <span>{{text.fileName}}</span>
        </template>
      </a-table-column>

      <a-table-column title="上传进度" align="center">
        <template slot-scope="text">
          <span v-if="text.type === 'course'">
            <span v-if="text.error" style="color: red">上传失败</span>
            <span v-else>
              <a-progress :percent="text.progress || 0" />
            </span>
          </span>
          <span v-else></span>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center" width="120px">
        <template slot-scope="text, row, index">
          <a-space>
            <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <div style="margin-top: 8px">上传失败的文件请删除后重新选择</div>

    <a-modal title="新增课程" :visible="visible" @cancel="cancel" :footer="null">
      <a-form :colon="false" @submit="handleSubmit" :form="form">
        <a-form-item label="章节名称">
          <a-input
            v-decorator="[
              'name',
                              { rules: [{ required: true, message: '请输入！' }] },
                          ]"
          />
        </a-form-item>

        <a-form-item label="学习时长(分钟)">
          <a-input-number
            style="width: 100%"
            :min="1"
            :max="120"
            v-decorator="[
                              'duration',
                              { rules: [{ required: true, message: '请输入！' }] },
                          ]"
          />
        </a-form-item>

        <a-form-item label="排序值">
          <a-input-number
            style="width: 100%"
            :min="0"
            :max="9999"
            placeholder="排序值越大越靠前"
            v-decorator="[
                              'sort',
                              { rules: [{ required: true, message: '请输入！' }] },
                          ]"
          />
        </a-form-item>

        <a-form-item label="附件">
          <input
            ref="uploadFile"
            style="display: none"
            type="file"
            :multiple="false"
            @change="onFileChange"
          />
          <a-button type="primary" @click="onClick">{{ file && file.name ? '重新上传' : '上传' }}</a-button>
        </a-form-item>
        <div style="color:#f5222d">
          上传的课程视频请限制在较低大小（建议使用格式工厂等工具进行压缩，推荐尺寸480P/720P，推荐码率256K/384k）
        </div>
        <div class="right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" :loading="addLoading" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { fetchFileKey } from "@/api/knowledge/qiniu";
import * as qiniu from "qiniu-js";
import Exam from "./exam.vue";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  components: {
    Exam
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      file: null,
      addLoading: false
    };
  },

  methods: {
    onSelectExam(exam) {
      console.log("exam", exam);

      const item = {
        key: Date.now().toString() + Math.random(),
        examId: exam.id,
        name: exam.name,
        duration: exam.duration,
        sort: exam.sort,
        type: "exam"
      };

      this.$emit("change", [...this.list, item]);
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.file) {
            this.$message.error("请选择文件");
            return;
          }

          this.addLoading = true;

          fetchFileKey({
            names: [this.file.name]
          })
            .then(res => {
              console.log("fetchFileKey", res);
              if (res && typeof res === "object") {
                const item = {
                  key: Date.now().toString() + Math.random(),
                  ...values,
                  fileName: this.file.name,
                  progress: 0,
                  error: false,
                  type: "course",
                  uptoken: res.uptoken,
                  uploadKey: res[this.file.name]
                };

                item.subscription = this.upload2QiNiu(
                  item,
                  this.file,
                  item.uploadKey,
                  item.uptoken
                );

                this.$emit("change", [...this.list, item]);

                this.cancel();
              }
            })
            .finally(() => {
              this.addLoading = false;
            });
        }
      });
    },

    cancel() {
      this.file = {};
      this.visible = false;
      this.form.resetFields();
    },

    upload2QiNiu(item, file, key, token) {
      const observable = qiniu.upload(file, key, token);
      return observable.subscribe({
        next(res) {
          // console.log("uploadInfo", res);
          if (res.total) {
            item.progress = Math.round(res.total.percent);
            // item.uploadInfo = res;
            // const item = list[index];
            // list.splice(index, 1, {
            //   ...item,
            //   progress: Math.round(res.total.percent),
            //   uploadInfo: res
            // });
          }
        },
        error(err) {
          console.log("upload err", err);
          item.error = true;
          // const item = list[index];
          // list.splice(index, 1, {
          //   ...item,
          //   error: true
          // });
        }
      });
    },

    remove(index) {
      const that = this;
      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          const res = that.list.splice(index, 1);
          res.forEach(item => {
            if (item.progress < 100) {
              if (item.subscription) {
                console.log("删除后取消上传");
                item.subscription.unsubscribe();
                // if (item.uploadInfo) {
                //   console.log("删除后删除已上传的 chunks");
                //   qiniu.deleteUploadedChunks(
                //     item.uptoken,
                //     item.uploadKey,
                //     item.uploadInfo
                //   );
                // }
              }
            }
          });
          that.$emit("change", that.list);
        }
      });
    },

    onClick() {
      this.$refs.uploadFile.click();
    },

    onFileChange(e) {
      const files = e.target.files;
      if (files.length === 0) return;
      this.file = files[0];
    }
  }
};
</script>